/** Fonts **/

// Zeitung Micro
@import url("https://use.typekit.net/wbs7dyi.css");

p,
h1,
h2,
h3,
h4,
h5,
h6,
button,
ul,
li,
code,
a {
    font-family: zeitung-micro, sans-serif;
    font-weight: 400;
    font-style: normal;
}
