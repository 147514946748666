$spacer: 1rem;
$break-small: 835px;

html {
    box-sizing: border-box;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

h1 {
    font-size: calc(1.5rem + 1.5vw);
    line-height: calc(1.75rem + 2vw);
}

h2 {
    font-size: calc(1.25rem + 1.5vw);
}

h3 {
    font-size: calc(1rem + 1.5vw);
    line-height: calc(1.25rem + 2vw);
}

h4 {
    font-size: calc(0.75rem + 1.5vw);
    line-height: calc(1rem + 2vw);
}

h5 {
    font-size: calc(0.5rem + 1.5vw);
    line-height: calc(0.75rem + 2vw);
}

h6 {
    font-size: calc(0.15rem + 1.5vw);
    line-height: calc(0.15rem + 1.5vw);

    @media screen and (max-width: $break-small) {
        font-size: calc(1.15rem + 1.5vw);
        line-height: calc(1.15rem + 1.5vw);
    }
}

p {
    font-size: calc(0.75rem + .5vw);
    line-height: calc(0.75rem + 1.5vw);;
}

/*
 * Colors
 */

.vm-green {
    color: #3b9662;
}

.white {
    color: white;
}

.black {
    color: black;
}

.bg-vm-green {
    background-color: #3b9662;
}

.bg-white {
    background-color: white;
}

.bg-gray {
    background-color: rgba(141, 141, 141, 0.22);
}

.vm-gray {
    color: rgba(141, 141, 141);
}

.gray {
    color: #707070
}

.bg-sparkshoppe {
    background-color: #F15E2A;
}

.sparkshoppe {
    color: #F15E2A;
}

.error {
    color: #DE0000;
}

.border-vm-green {
    border-color: #3b9662;
}

.bg-glass {
    /* slightly transparent fallback */
    background-color: rgba(0, 0, 0, 0.6);

    /* if backdrop support: very transparent and blurred */
    @supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
        background-color: rgba(0, 0, 0, 0.3);
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
    }
}

.bg-image-center {
    background-size: cover;
    overflow: hidden;
    background-position: center;
}

.error {
    color: red;
}

/*
 * Positioning
 */

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}


.flex-wrap {
    flex-wrap: wrap;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-around {
    justify-content: space-around;
}

.justify-content-end {
    justify-content: flex-end;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: flex-end;
}

.align-items-baseline {
    align-items: baseline;
}

.align-self-start {
    align-self: flex-start;
}

.align-self-end {
    align-self: flex-end;
}

.align-self-center {
    align-self: center;
}

.flex-end {
    justify-content: flex-end;
}

.flex-start {
    justify-content: flex-start;
}

.wrap {
    flex-wrap: wrap;
}

.text-left {
    text-align: left;
}

.m-hor-center {
    margin-left: auto;
    margin-right: auto;
}

/*
 * Font Styling
 */

.text-decoration-none {
    text-decoration: none;
}

.italic {
    font-style: italic;
}

/*
 * Display Styling
 */

.d-inline {
    display: inline;
}

/**x
 * Screen sizing
 */

.max-1366 {
    max-width: 1366px;
}

/**
 * Element styling
 */
.border-none {
    border: none!important;
}

.mobile {
    display: none!important;
    @media screen and (max-width: $break-small) {
        display: unset!important;
    }
}

.vm-blue {
    color: rgb(42, 105, 161);
}

.bg-vm-blue {
    background-color: rgb(42, 105, 161);
}

.zoom-gray {
    color: #ababab;
}

.vm-bg-gradient {
    background: rgb(42,105,161);
    background: linear-gradient(90deg, rgba(42,105,161,1) 0%, rgba(59,150,98,1) 100%);
}

.shadow-white {
    --tw-shadow-color: #fff;
}

.vimax-icon {
    display: inline-block;
    user-select: none;
    vertical-align: text-bottom;
}

.border-white {
    border-color: white;
}

.border-gray {
    border-color: #2E2E2E;
}
