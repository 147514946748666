$break-small: 600px;

.nav-desktop-wrapper {
    position: relative;

    .bar {
        padding: 1em 0;
        display: flex;

        -moz-box-shadow:    0px 1px 5px 1px #707070;
        -webkit-box-shadow: 0px 1px 5px 1px #707070;
        box-shadow:         0px 1px 5px 1px #707070;

        position: relative;

        #logo {
            position: absolute;
            margin-left: 0;
            margin-right: 0;
            left: 50%;
            top: 5%;

            @media screen and (max-width: $break-small) {
                left: 60%;
                right: 20%;
                top: 50%;
            }


            .half {
                transform: translate(-50%, 0);
                overflow-x: hidden;
                position: absolute;

                @media screen and (max-width: $break-small) {
                    transform: translate(0, 0)!important;
                    max-width: 100px;
                }
            }

            .full {
                transform: translate(-50%, 0);
                overflow-x: hidden;

                @media screen and (max-width: $break-small) {
                    transform: translate(-50%, -50%);
                    max-width: 200px;
                    width: auto!important;
                }
            }
        }
    }
}

.menu {
    a {
        text-decoration: none;
        color: white;
        padding: 7px 25px;
        position: relative;
        font-size: 1.1rem;

        span {
            font-size: 0.8rem;
        }
    }

    div {
        padding: 7px 25px;
    }

    a:visited {
        text-decoration: none;
        color: white;
    }
}
