@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

body {
    margin: 0;
    font-family: zeitung-micro, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body, html { overflow-x:hidden; }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}
