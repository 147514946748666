$break-small: 1000px;

.footer-wrapper {

    .bottom {
        padding-top: 30px;
        padding-bottom: 30px;

        @media screen and (max-width: $break-small) {
            flex-direction: column;
            align-items: center;
        }

        .social {
            width: 15%;

            @media screen and (max-width: $break-small){
                width: 100%;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                order: 3;
            }

            div {

                @media screen and (max-width: $break-small){
                    margin: 0!important;
                }
                a {
                    width: 50%;
                }
            }
        }

        .subscribe-text {
            width: 30%;

            @media screen and (max-width: $break-small){
                width: 70%;
            }
        }

        form {

            @media screen and (max-width: $break-small){
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 1em;
            }

            input {
                width: 35%;
                height: 2rem;
                border-radius: 5px;
                border-style: solid;
                border-color: #707070;

                @media screen and (max-width: $break-small){
                    width: 70%;
                    transform: unset;
                }
            }

            button {
                width: 100px;
                border-radius: 5px;
                height: 2em;

                @media screen and (max-width: $break-small){
                    width: 25%;
                    transform: unset;
                }
            }

            label {
                font-size: .9em;
            }
        }
    }

    .links {
        //width: 90%;

        a {
            text-decoration: none;
        }
    }
}
